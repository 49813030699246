export interface Column {
  Header: string;
  accessor: string;
  style?: string; // style all row elements of a particular column
  width?: string; // width for each column
  sort?: boolean; // enable sorting for a column
}

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}

export interface TableProps {
  columns: Column[];
  data: any[];
  isRowClickRequired?: boolean;
  rowDataStyle?: string; // style all the row elements
  headerDataStyle?: string; // style all the column header elements
  selectedRow?: any;
  handleSelect?: (data: any) => void;
  setLastRow?: (lastRow: any) => void;
  className?: string;
  showNoResults?: boolean;
  noResultText?: string;
  onSortClick?: (column: string, sortOrder: SortOrder) => void;
  sortOrder?: SortOrder;
  sortBy?: any;
  tableHeaderCellStyle?: string;
}
