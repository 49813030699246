import clsx from 'clsx';

import { TabProps, TabType } from '@types';

const Tabs = ({
  type = TabType.DEFAULT,
  tabs,
  onTabSelect,
  selectedTab,
  className,
  tabStyles
}: TabProps) => {
  const getStyle = (tabId: number) => {
    const defaultStyles =
      'table-cell truncate px-4 py-1 text-center text-xs desktop:text-base font-semibold tablet:text-base';
    if (type === TabType.DEFAULT) {
      if (selectedTab?.id === tabId) return `${defaultStyles} text-primary`;
      return `${defaultStyles} text-grey10`;
    }
    if (type === TabType.CHIP) {
      if (selectedTab?.id === tabId)
        return `${defaultStyles} rounded-xl bg-warning text-theme`;
      return `${defaultStyles} rounded-xl border border-warning text-warning`;
    }
    return defaultStyles;
  };

  return (
    <div
      className={`flex ${
        type === TabType.DEFAULT
          ? 'border-b-2 border-grey40'
          : 'flex items-center justify-start gap-3'
      } ${className || ''}`}
    >
      {tabs?.map((tab) => (
        <div
          role="presentation"
          className={clsx(
            'min-w-180 flex cursor-pointer flex-col',
            tabStyles || ''
          )}
          key={tab.id}
          onClick={() => {
            onTabSelect?.(tab);
          }}
        >
          <div className={getStyle(tab.id)} key={tab.id}>
            {tab.label}
          </div>
          {type === TabType.DEFAULT && (
            <div
              className={`relative top-0.5 border-b-4 border-primary ${
                selectedTab?.id !== tab.id && 'invisible'
              }`}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default Tabs;
