import React, { FC } from 'react';
import clsx from 'clsx';

import { ErrorCardProps } from '@types';
import useMascotTheme from '@hooks/useMascotTheme';

import Button from '../button/Button';

const ErrorCard: FC<ErrorCardProps> = (props) => {
  const {
    header,
    content,
    handleHomeButtonClick,
    mascotImage,
    className = '',
    buttonLabel = 'Take me home',
    isButtonDisabled = false
  } = props;

  const mascotTheme = useMascotTheme();

  return (
    <div
      className={clsx(
        'rounded-48px mx-4 flex flex-col-reverse items-center justify-center bg-white p-6 shadow-errorCard lg:flex-row lg:justify-between lg:p-20 xl:mx-auto xl:min-w-1080',
        className
      )}
    >
      <div className="min-w-full whitespace-break-spaces text-center lg:min-w-520 lg:max-w-520 lg:text-left">
        <h1 className=" text-4xl font-semibold leading-snug text-lightBlack sm:leading-72 lg:text-6xl">
          {header}
        </h1>
        <p className="mt-4 text-sm text-lightBlack80">{content}</p>
        {!isButtonDisabled && (
          <Button
            label={buttonLabel}
            handleClick={handleHomeButtonClick}
            className="mx-auto mt-8 min-h-14 rounded-full lg:mx-0 lg:min-w-280"
          />
        )}
      </div>
      <img
        src={mascotImage ?? mascotTheme.errorScreen.mascotImage}
        alt="error"
        className="h-40 w-24 md:h-350 md:w-230 lg:h-411 lg:w-257"
      />
    </div>
  );
};

export default ErrorCard;
