import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { HOMEWORKS } from '@constants/routes';
import AccessGuard from '@contexts/AccessGuard';
import { RoleType } from '@types';

const HomeWorks = lazy(
  () => import('@features/homeworks/pages/homeworks/Homeworks')
);

const Submission = lazy(
  () => import('@features/homeworks/pages/submission/Submission')
);

const Review = lazy(() => import('@features/homeworks/pages/review/Review'));

const Report = lazy(
  () => import('@features/homeworks/pages/student-report/StudentReport')
);

export const HomeworkRoutes: RouteObject[] = [
  {
    path: HOMEWORKS.HOMEWORKSLIST,
    element: <HomeWorks />
  },
  {
    path: HOMEWORKS.SUBMISSION,
    element: (
      <AccessGuard role={RoleType.STUDENT}>
        <Submission />
      </AccessGuard>
    )
  },
  {
    path: HOMEWORKS.REVIEW,
    element: (
      <AccessGuard role={RoleType.STUDENT}>
        <Review />
      </AccessGuard>
    )
  },
  {
    path: HOMEWORKS.REVIEW_BY_STUDENT_ID,
    element: (
      <AccessGuard role={RoleType.PARENT}>
        <Review />
      </AccessGuard>
    )
  },
  {
    path: HOMEWORKS.STUDENT_REPORT,
    element: (
      <AccessGuard role={RoleType.PARENT}>
        <Report />
      </AccessGuard>
    )
  }
];

export default HomeworkRoutes;
