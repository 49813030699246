/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactComponent as DashboardIcon } from '@assets/icons/dashboard.svg';
import { ReactComponent as HomeWorkIcon } from '@assets/icons/homework.svg';
import { ReactComponent as NotificationIcon } from '@assets/icons/notifications.svg';
import { ReactComponent as SettingsIcon } from '@assets/icons/settings.svg';

import { DASHBOARD, HOMEWORKS, NOTIFICATIONS, SETTINGS } from './routes';

// TODO: Add dashboard later
const MENU_ITEMS = [
  // {
  //   id: 1,
  //   Icon: DashboardIcon,
  //   label: 'Dashboard',
  //   route: DASHBOARD.DASHBOARD
  // },
  {
    id: 2,
    Icon: HomeWorkIcon,
    label: 'Homeworks',
    route: HOMEWORKS.HOMEWORKSLIST
  },
  {
    id: 3,
    Icon: NotificationIcon,
    label: 'Notifications',
    route: NOTIFICATIONS.NOTIFICATIONS
  },
  {
    id: 4,
    Icon: SettingsIcon,
    label: 'Settings',
    route: SETTINGS.SETTINGS
  }
];

export default MENU_ITEMS;
