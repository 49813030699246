import { FC } from 'react';
import { ProgressStatusList } from '@features/homeworks/constants';
import { ProgressStatusType } from 'types/homework';

interface ProgressStatusProps {
  text?: string;
  textColor?: string;
  backgroundColor?: string;
  type: ProgressStatusType;
  iconColor?: string;
}

const ProgressStatus: FC<ProgressStatusProps> = ({
  text,
  type = ProgressStatusList.YET_TO_START,
  textColor,
  backgroundColor,
  iconColor
}) => {
  const {
    YET_TO_START,
    IN_PROGRESS,
    COMPLETED,
    CUSTOM,
    REVIEW,
    OVERDUE,
    LATE_SUBMISSION,
    YET_TO_START_OVERDUE,
    IN_PROGRESS_OVERDUE
  } = ProgressStatusList;
  const typeStyles = {
    [YET_TO_START]: {
      styles: 'text-blue600 bg-blue50',
      dotColor: 'bg-blue600',
      textToShow: 'Yet To Start'
    },
    [IN_PROGRESS]: {
      styles: 'text-warning600 bg-warning50',
      dotColor: 'bg-warning500',
      textToShow: 'In Progress'
    },
    [COMPLETED]: {
      styles: 'text-green bg-success50',
      dotColor: 'bg-green',
      textToShow: 'Completed'
    },
    [REVIEW]: {
      styles: 'text-green bg-success50',
      dotColor: 'bg-green',
      textToShow: 'Completed'
    },
    [OVERDUE]: {
      styles: 'text-blue600 bg-blue50',
      dotColor: 'bg-blue600',
      textToShow: 'Yet To Start'
    },
    [YET_TO_START_OVERDUE]: {
      styles: 'text-blue600 bg-blue50',
      dotColor: 'bg-blue600',
      textToShow: 'Yet To Start'
    },
    [IN_PROGRESS_OVERDUE]: {
      styles: 'text-warning600 bg-warning50',
      dotColor: 'bg-warning500',
      textToShow: 'In Progress'
    },
    [LATE_SUBMISSION]: {
      styles: 'text-green bg-success50',
      dotColor: 'bg-green',
      textToShow: 'Completed'
    },
    [CUSTOM]: {
      styles: `text-${textColor} bg-${backgroundColor}`,
      dotColor: `bg-${iconColor || 'blue600'}`,
      textToShow: text || ''
    }
  };

  const styles = typeStyles[type];

  return (
    <div
      className={`font-14 flex w-126 items-center rounded-full px-4 py-1.5 leading-20 md:w-full ${styles.styles}`}
    >
      <span
        className={`mr-2.5 inline-block size-1.5 rounded-full ${styles.dotColor}`}
      />

      <span className="semibold text-sm">{text || styles.textToShow}</span>
    </div>
  );
};

export default ProgressStatus;
