import Icon from '../icon/Icon';

interface EmptyScreenProps {
  iconName?: string;
  heading: string;
  children?: React.ReactNode;
  className?: string;
  headingStyle?: string;
}

const EmptyScreen = ({
  iconName,
  heading,
  children,
  className,
  headingStyle
}: EmptyScreenProps) => (
  <div
    className={`mt-8 flex w-full flex-col items-center justify-center ${className}`}
  >
    {!!iconName && (
      <div className="flex size-16 items-center justify-center rounded-2xl bg-primary1">
        <Icon name={iconName} size="24" />
      </div>
    )}
    <div className="mt-6 text-center">
      <p className={`text-lg font-semibold leading-6 ${headingStyle}`}>
        {heading}
      </p>
      {children}
    </div>
  </div>
);

export default EmptyScreen;
