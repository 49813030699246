/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect } from 'react';

import Icon from '../icon/Icon';
import Button from '../button/Button';

import colors from '../../../themes/colors';

interface FileUploaderProps {
  id: string;
  name: string;
  fileCriteriaMsgs?: string;
  disabled?: boolean;
  accept: string;
  fileType: 'image';
  setUploadedFiles: React.Dispatch<React.SetStateAction<File[]>>;
  uploadBtnText: string;
  maxNoOfFiles?: number;
  onUploadCancel: () => void;
  uploadedFiles: File[];
}

const FileUploader: React.FC<FileUploaderProps> = ({
  id,
  name,
  fileCriteriaMsgs,
  disabled = false,
  accept,
  fileType,
  setUploadedFiles,
  uploadBtnText,
  maxNoOfFiles = 1,
  onUploadCancel,
  uploadedFiles
}) => {
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [isInputDisabled, setIsInputDisabled] = useState<boolean>(disabled);
  const [isImageAvailable, setIsImageAvailable] = useState<boolean>(false);
  const [imageObjectArray, setImageObjectArray] = useState<any>([]);

  const imageLengthHandler = () => {
    if (imageObjectArray.length >= maxNoOfFiles) {
      setIsInputDisabled(true);
    } else {
      setIsInputDisabled(false);
    }
  };

  const uploadHandler = (
    event: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>
  ) => {
    setErrorMsg('');
    imageLengthHandler();
    event.preventDefault();

    const fileObject =
      event.type === 'drop'
        ? (event as React.DragEvent).dataTransfer.files[0]
        : (event as React.ChangeEvent<HTMLInputElement>).target.files?.[0];

    if (!fileObject) return;

    const fileExtension = fileObject.name.split('.').pop()?.toLowerCase();
    const acceptTypes = accept
      .split(',')
      .map((type) => type.trim().replace('.', ''));

    if (!acceptTypes.includes(fileExtension || '')) {
      setErrorMsg('Invalid File');
      return;
    }

    if (fileType === 'image') {
      setImageObjectArray([...imageObjectArray, fileObject]);
      (event.target as HTMLInputElement).value = '';
    }
  };

  const removeImage = (index: number) => {
    const updatedArray = [...imageObjectArray];
    updatedArray.splice(index, 1);
    setImageObjectArray(updatedArray);
  };

  const checkImageAvailable = () => {
    setIsImageAvailable(imageObjectArray.length > 0 && !disabled);
  };

  const imageLengthErrorThrowHandler = () => {
    if (imageObjectArray.length >= maxNoOfFiles) {
      setErrorMsg('Reached maximum number of files');
    }
  };

  useEffect(() => {
    checkImageAvailable();
    imageLengthHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageObjectArray]);

  useEffect(() => {
    setImageObjectArray(uploadedFiles);
  }, [uploadedFiles]);

  return (
    <div className="m-4 min-w-544 max-w-544 rounded-3xl bg-primaryLite p-5">
      {fileCriteriaMsgs && (
        <span className="mt-4 text-2xl font-semibold text-primary5">
          {fileCriteriaMsgs}
        </span>
      )}
      {!isImageAvailable ? (
        <div
          id={`${id}_wrapper`}
          className={` min-h-160 my-9 flex cursor-pointer items-center justify-center rounded-md border border-gray-300 ${
            disabled ? 'cursor-not-allowed' : ''
          }`}
          onDrop={(e) => (!disabled ? uploadHandler(e) : undefined)}
          onDragOver={(e) => e.preventDefault()}
          onDragEnter={(e) => e.preventDefault()}
        >
          <label
            className={`flex flex-col items-center justify-center p-4 ${
              disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
            }`}
            htmlFor={id}
          >
            <Icon
              name="file-upload"
              stroke={colors.grey7}
              fill=""
              size="extralarge"
            />
            <span>{uploadBtnText}</span>
            <input
              name={name}
              id={id}
              type="file"
              accept={accept}
              className="hidden"
              onChange={uploadHandler}
              disabled={disabled}
            />
          </label>
        </div>
      ) : (
        <div
          className="my-9 flex flex-wrap"
          onDrop={!isInputDisabled ? uploadHandler : undefined}
          onDragOver={(e) => e.preventDefault()}
          onDragEnter={(e) => e.preventDefault()}
        >
          {imageObjectArray.map((item: any, index: number) => (
            <div
              className="relative mr-2 mt-2 size-40"
              key={`${item.name}-${index + 1}`}
            >
              <img
                src={URL.createObjectURL(item)}
                alt="preview"
                className="size-full rounded-xl"
                onLoad={() => URL.revokeObjectURL(item)}
              />
              <div
                className="absolute right-2 top-2 size-8 cursor-pointer"
                role="button"
                tabIndex={0}
                onClick={() => removeImage(index)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') removeImage(index);
                }}
              >
                <Icon
                  name="delete"
                  stroke={colors.grey7}
                  fill=""
                  size="extralarge"
                  className="size-full"
                />
              </div>
            </div>
          ))}
          {!isInputDisabled && (
            <div className="mt-2 size-40 rounded-xl bg-gray-300">
              <label
                className="flex size-full cursor-pointer items-center justify-center"
                htmlFor={id}
                onClick={imageLengthErrorThrowHandler}
                role="presentation"
              >
                <Icon
                  name="plus"
                  stroke={colors.grey10}
                  fill=""
                  size="extralarge"
                />
                <input
                  name={name}
                  id={id}
                  type="file"
                  accept={accept}
                  className="hidden"
                  onChange={uploadHandler}
                  disabled={isInputDisabled}
                />
              </label>
            </div>
          )}
        </div>
      )}
      {errorMsg && (
        <div className="mt-2 flex items-center">
          <Icon
            name="exclamation"
            stroke={colors.warning500}
            fill=""
            size="extralarge"
          />
          <span className="ml-2 text-sm text-red-600">{errorMsg}</span>
        </div>
      )}
      <div className="flex w-full items-center justify-end gap-4">
        <Button
          variant="secondary"
          label="Cancel"
          handleClick={onUploadCancel}
        />
        <Button
          variant="primary"
          label="Continue"
          handleClick={() => {
            setUploadedFiles(imageObjectArray);
            onUploadCancel();
          }}
        />
      </div>
    </div>
  );
};

export default FileUploader;
