import Button from '../button/Button';

interface ClearNotificationsProps {
  handleClearAllModalCancel: () => void;
  handleClearAll: () => void;
}

const ClearNotifications = ({
  handleClearAllModalCancel,
  handleClearAll
}: ClearNotificationsProps) => (
  <div className="b-primary0 md:min-w-500 rounded-3xl p-5 md:max-w-500">
    <div className="mb-5 mt-10 rounded-2xl bg-theme p-4">
      Do you want to clear all notifications?
    </div>
    <div className="flex items-center justify-end gap-2">
      <Button
        label="Cancel"
        variant="secondary"
        size="small"
        handleClick={handleClearAllModalCancel}
      />
      <Button label="Clear all" size="small" handleClick={handleClearAll} />
    </div>
  </div>
);

export default ClearNotifications;
