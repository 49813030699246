import React, { FC } from 'react';

import { NotificationDetailsProps } from '@types';
import { formatISODate } from '@utils/date';

import Icon from '../icon/Icon';
import Avatar from '../avatar/Avatar';
import Chip from '../chip/Chip';
import Button from '../button/Button';

const NotificationDetails: FC<NotificationDetailsProps> = (props) => {
  const { notificationDetails, onButtonClick, buttonLabel } = props;

  return (
    <div className="b-primary0 sm:min-w-500 flex flex-col gap-y-4 rounded-3xl p-4 sm:max-w-500">
      <div className="flex gap-4">
        <Avatar
          name={notificationDetails?.additionalData?.userName || 'Tara'}
          className="size-12 rounded-lg text-sm text-white"
          bgColor="#7C57FF"
        />
        <div className="flex flex-col gap-y-2">
          <h3 className="...truncate max-w-60 font-semibold ">
            {notificationDetails?.message?.header}
          </h3>
          <div className="flex items-center gap-3 mobile:gap-2">
            {notificationDetails?.additionalData?.type && (
              <Chip
                isCloseIconRequired={false}
                label={notificationDetails?.additionalData?.type}
                className="bg-yellow75 px-2 text-yellow700"
              />
            )}
            <p className="flex items-center gap-1 text-neutral6 mobile:text-xs tablet:text-sm">
              <Icon name="calendar" />
              <span>
                {formatISODate({
                  date: notificationDetails?.createdAt,
                  format: 'MMM DD',
                  dateSuffix: true
                })}
              </span>
            </p>
            <p className="flex items-center gap-1 text-neutral6 mobile:text-xs tablet:text-sm">
              <Icon name="clock" />
              <span>
                {formatISODate({
                  date: notificationDetails?.createdAt,
                  format: 'hh:mm'
                })}
              </span>
            </p>
          </div>
        </div>
      </div>
      <p className="rounded-2xl bg-white p-5 text-sm mobile:p-4">
        {notificationDetails?.message?.body}
      </p>
      {onButtonClick && buttonLabel && (
        <div className="mt-8 flex justify-center">
          <Button label={buttonLabel} handleClick={onButtonClick} />
        </div>
      )}
    </div>
  );
};

export default NotificationDetails;
