export const API_END_POINT = `${process.env.REACT_APP_API_URL}/api`;
export const API_VERSION = '/v1';
export const END_POINTS = {
  AUTH: {
    SEND_OTP: '/auth/send-otp',
    VERIFY_OTP: '/auth/verify-otp',
    REFRESH: '/auth/refresh-token',
    USER: '/user/me'
  },
  HOMEWORKS: {
    GET_QUESTION_MAPPING: (assignmentId: string) =>
      `/homework/${assignmentId}/question-mappings`,
    GET_QUESTION_DETAILS: ({
      assignmentId,
      questionId
    }: {
      assignmentId: string;
      questionId: string;
    }) => `/homework/${assignmentId}/question/${questionId}`,
    GET_FILE_UPLOAD_LINK: '/file/upload-url',
    HOMEWORK_LIST: 'homework',
    HOMEWORK_DETAILS: (assignmentId: string) => `/homework/${assignmentId}`,
    START_HOMEWORK: (assignmentId: string) => `/homework/${assignmentId}/start`,
    SUBMIT_ANSWER: (assignmentId: string, questionId: string) =>
      `homework/${assignmentId}/question/${questionId}/submit`,
    HOMEWORK_SUMMARY: (assignmentId: string) =>
      `/homework/${assignmentId}/summary`,
    OVERALL_PERFORMANCE: (assignmentId: string) =>
      `/homework/${assignmentId}/overall-performance`,
    HOMEWORK_SUMMARY_BY_STUDENT_ID: (assignmentId: string, studentId: string) =>
      `/homework/${assignmentId}/student/${studentId}/summary`,
    OVERALL_PERFORMANCE_BY_STUDENT_ID: (
      assignmentId: string,
      studentId: string
    ) => `/homework/${assignmentId}/student/${studentId}/overall-performance`,
    HOMEWORK_DETAILS_BY_STUDENT_ID: (assignmentId: string, studentId: string) =>
      `/homework/${assignmentId}/student/${studentId}`,
    GET_QUESTION_DETAILS_BY_STUDENT_ID: ({
      assignmentId,
      questionId,
      studentId
    }: {
      assignmentId: string;
      questionId: string;
      studentId: string;
    }) =>
      `/homework/${assignmentId}/question/${questionId}/student/${studentId}`
  },
  SETTINGS: {
    SUBMIT_FEEDBACK: '/settings/feedback',
    GET_FILE_UPLOAD_LINK: '/file/upload-url',
    CONTACT_SUPPORT: '/settings/support-request',
    DELETE_ACCOUNT: '/user/delete',
    GET_FAQ_LIST: '/settings/faq',
    USER_SUPPORT_REQUEST: '/settings/support-request/me',
    STUDENT_DETAILS: '/student'
  },
  PARENT: {
    GET_HOMEWORKS_BY_STUDENT_ID: (studentId: string) =>
      `/homework/student/${studentId}`,
    STUDENT_AVG_SCORE_TIME: (studentId: string) =>
      `/assignment-report/parent/student/${studentId}/average-score-time`,
    STUDENT_FEEDBACK_RECOMMENDATION: (studentId: string) =>
      `/assignment-report/parent/student/${studentId}/feedback-recommendation`,
    STUDENT_HOMEWORK_COMPLETION_RATE: (studentId: string) =>
      `/assignment-report/parent/student/${studentId}/completion-rate`,
    STUDENT_SKILL_REPORT: (studentId: string) =>
      `/assignment/parent/skills-report/student/${studentId}`,
    STUDENT_SCORE_TREND_OVER_TIME: (studentId: string) =>
      `/assignment-report/parent/student/${studentId}/homework-score-trend`,
    STUDENT_REPORT_HOMEWORK_SCORE_LIST: (studentId: string) =>
      `/assignment-report/parent/student/${studentId}/homework-score-list`,
    STUDENT_SUBJECT_LIST: (studentId: string) => `/student/${studentId}/subject`
  }
};
