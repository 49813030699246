export enum TabType {
  CHIP = 'chip',
  DEFAULT = 'default'
}

export interface TabPill {
  id: number;
  label: string;
  value?: string;
}

export interface TabProps {
  type?: TabType;
  tabs: TabPill[];
  onTabSelect?: (tab: TabPill) => void;
  selectedTab?: TabPill;
  className?: string;
  tabStyles?: string;
}
