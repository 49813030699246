import mascot404 from '@assets/images/mascots/404.png';
import mascot500 from '@assets/images/mascots/500.png';
import notFound from '@assets/images/mascots/no_result_found.png';

export const SOMETHING_WENT_WRONG = 'SOMETHING_WENT_WRONG';

export const errorScreenData = {
  '404': {
    header: 'Oopss, 404 page not found',
    content:
      "It looks like the page you're trying to find doesn't exist. This might be because of a mistyped URL or an out-of-date link. Please check the URL or head back to the homepage.",
    mascotImage: mascot404
  },
  '500': {
    header: 'Oopss, 500 server error',
    content:
      "We're sorry, looks like something went wrong on our end. Please try refreshing the page or come back later. If the issue persists, contact our support team for assistance.",
    mascotImage: mascot500
  },
  '401': {
    header: 'Oopss, 401 User not authorized to access this content',
    content:
      "It looks like the page you're trying to cannot access by you. This might be because of a mistyped URL or an out-of-date link. Please check the URL or head back to the homepage.",
    mascotImage: mascot404
  },
  '503': {
    header: 'Oopss, 503 service unavailable',
    content:
      "We're sorry, looks like something went wrong on our end. Please try refreshing the page or come back later. If the issue persists, contact our support team for assistance.",
    mascotImage: mascot500
  },
  [SOMETHING_WENT_WRONG]: {
    header: 'Something went wrong!, Please try again',
    content:
      "We're sorry, looks like something went wrong on our end. Please try refreshing the page or come back later. If the issue persists, contact our support team for assistance.",
    mascotImage: notFound
  }
};
