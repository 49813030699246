export enum BarChartType {
  BASIC = 'BASIC',
  STACKED = 'stacked'
}

export interface BarChartProps {
  type?: BarChartType;
  labels: string[];
  primaryValue: number[];
  secondaryValue?: number[];
  backgroundColor: string[];
  showDataLabel?: boolean;
  isHorizontal?: boolean;
  barThickness?: number;
  dataLabelFontType?: 'mobile' | 'desktop';
}

export interface PieChartProps {
  label: string[];
  value: number[];
  colors: string[];
  showDataLabel?: boolean;
}

export interface LineChartProps {
  labels: string[];
  primaryValue: number[];
  secondaryValue: number[];
  primaryColor: string;
  secondaryColor: string;
}
