interface ProgressBarProps {
  height: number;
  progress: number;
  progressBarColor: string;
  bgColor: string;
  wrapperStyle?: string;
  progressBarStyle?: string;
  isIndicator?: boolean;
  indicatorColor?: string;
}

const ProgressBar = ({
  height,
  progress,
  progressBarColor,
  bgColor,
  wrapperStyle,
  progressBarStyle,
  isIndicator = false,
  indicatorColor
}: ProgressBarProps) => {
  const styles = {
    progressWrapperStyle: {
      height,
      backgroundColor: bgColor
    },
    progressBarStyle: {
      width: `${progress}%`,
      backgroundColor: progressBarColor
    }
  };

  return (
    <div
      style={styles.progressWrapperStyle}
      className={`relative w-full rounded-full ${wrapperStyle}`}
    >
      <div
        style={styles.progressBarStyle}
        className={`h-full rounded-full ${progressBarStyle}`}
      />
      {isIndicator && (
        <div
          className={`absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 ${
            indicatorColor || `${progress < 30 ? 'text-primary' : 'text-theme'}`
          }`}
        >{`${progress}% success`}</div>
      )}
    </div>
  );
};

export default ProgressBar;
