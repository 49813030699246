import React, { Component, ErrorInfo, ReactNode } from 'react';

import ErrorPage from '@features/error/pages/error-page/ErrorPage';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // eslint-disable-next-line no-console
    console.error('ErrorBoundary caught an error', error, errorInfo);
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    const isLocalEnv = process.env.NODE_ENV === 'development';

    if (hasError) {
      // You can render any custom fallback UI
      return isLocalEnv ? children : <ErrorPage />;
    }

    return children;
  }
}

export default ErrorBoundary;
