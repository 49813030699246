import { FC } from 'react';

import colors from '../../../themes/colors';

interface CircularProgressBarProps {
  completed: number;
  total: number;
  size?: number;
  strokeWidth?: number;
  color?: string;
  backgroundColor?: string;
  className?: {
    progressBar?: string;
    progressBackground?: string;
    innerText?: string;
    progressText?: string;
    background?: string;
  };
}

const CircularProgressBar: FC<CircularProgressBarProps> = ({
  completed,
  total,
  size = 100,
  strokeWidth = 10,
  color = colors.primary3,
  backgroundColor = colors.primary1,
  className
}) => {
  const center = size / 2;
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const progress = (completed / total) * 100;
  const offset = Number(circumference - (progress / 100) * circumference);

  return (
    <div
      className={`relative flex items-center justify-center ${className?.background}`}
      style={{ width: size, height: size }}
    >
      <svg width={size} height={size} className="-rotate-90">
        <circle
          className={`text-purple1 ${className?.progressBar}`}
          stroke={backgroundColor}
          fill="none"
          strokeWidth={strokeWidth}
          r={radius}
          cx={center}
          cy={center}
        />
        <circle
          className={`text-purple3 ${className?.progressBackground}`}
          stroke={color}
          fill="none"
          strokeWidth={strokeWidth}
          r={radius}
          cx={center}
          cy={center}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          strokeLinecap="round"
          style={{ transition: 'stroke-dashoffset 0.35s' }}
        />
      </svg>
      <div className="absolute flex flex-col items-center justify-center text-center">
        <div className={`text-xs text-grey5 ${className?.innerText}`}>
          Submitted
        </div>
        <div
          className={`text-base font-semibold leading-22 text-grey10 ${className?.progressText}`}
        >
          {completed}
        </div>
      </div>
    </div>
  );
};

export default CircularProgressBar;
