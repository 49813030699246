import Icon from '../icon/Icon';

interface MobilePageHeaderProps {
  title: string;
  onBackClick: () => void;
  showBackButton?: boolean;
}

const MobilePageHeader = ({
  title,
  onBackClick,
  showBackButton = true
}: MobilePageHeaderProps) => (
  <div className="mb-4 flex items-center justify-start gap-3">
    {showBackButton && (
      <div>
        <Icon
          name="back"
          size="medium"
          onClick={onBackClick}
          className="cursor-pointer"
        />
      </div>
    )}
    <div className="text-xl font-bold text-content">{title}</div>
    <div />
  </div>
);

export default MobilePageHeader;
