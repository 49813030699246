import homeworkActiveMascot from './homework_active.png';
import homeworkCompletedHappyMascot from './homework_completed_happy.png';
import homeworkCompletedNeutralMascot from './homework_completed_neutral.svg';
import homeworkCompletedSadMascot from './homework_completed_sad.svg';
import homeworkOverdueMascot from './homework_overdue.svg';
import errorPageMascot from './error_page.svg';
import resultCorrectMascot from './result_correct.svg';
import resultPartiallyCorrectMascot from './result_partially_correct.svg';
import resultIncorrectMascot from './result_incorrect.svg';
import summaryMascot from './summary.svg';

const mascotTheme = {
  homeworkCard: {
    mascotImage: {
      active: homeworkActiveMascot,
      completedHappy: homeworkCompletedHappyMascot,
      completedNeutral: homeworkCompletedNeutralMascot,
      completedSad: homeworkCompletedSadMascot,
      overdue: homeworkOverdueMascot
    },
    textColor: 'text-blue700',
    progressBar: {
      bgColor: '#32C175',
      fgFromColor: '#E89241',
      fgToColor: '#EDAB6D'
    }
  },
  errorScreen: {
    mascotImage: errorPageMascot
  },
  resultDialog: {
    correctMascotImage: resultCorrectMascot,
    partiallyCorrectMascotImage: resultPartiallyCorrectMascot,
    incorrectMascotImage: resultIncorrectMascot
  },
  summaryDialog: {
    mascotImage: summaryMascot
  }
};

export default mascotTheme;
