import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ErrorCard from '@components/error-card/ErrorCard';
import backgroundImage from '@assets/images/error_page_background.svg';
import {
  errorScreenData,
  SOMETHING_WENT_WRONG
} from '@features/error/constants';

const ErrorPage = () => {
  const { errorStatus = SOMETHING_WENT_WRONG } = useParams();
  const navigate = useNavigate();

  const backgroundStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  };

  const handleHomeButtonClick = () => {
    navigate('/');
  };

  return (
    <div
      className="flex h-screen w-screen items-center justify-center"
      style={backgroundStyle}
    >
      <ErrorCard
        handleHomeButtonClick={handleHomeButtonClick}
        {...errorScreenData[errorStatus as keyof typeof errorScreenData]}
      />
    </div>
  );
};

export default ErrorPage;
