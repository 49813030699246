import { mascotThemes } from '@features/homeworks/config';
import { useSelector } from 'react-redux';

const useMascotTheme = () => {
  const { currentUser } = useSelector((state: any) => state.user);

  const mascotId = currentUser?.mascotId ?? 0;

  return mascotThemes[mascotId];
};

export default useMascotTheme;
