import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import Logo from '@assets/images/logo-black.svg';
import MENU_ITEMS from '@constants/sideMenu';
import ClickOutside from '@utils/clickOutside';
import { AUTH } from '@constants/routes';
import LogoutIcon from '@assets/icons/logout.svg';
import { clearAllLocalStorageItems } from '@utils/storage';

import Icon from '../icon/Icon';
import Avatar from '../avatar/Avatar';
import SidebarItem from '../sidebar-item/SidebarItem';

const Topbar = () => {
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const { currentUser } = useSelector((state: any) => state.user);

  ClickOutside({
    ref: menuRef,
    handler: () => {
      setIsMenuOpen(false);
    }
  });

  const onClickLogout = () => {
    clearAllLocalStorageItems();
    navigate(AUTH.LOGIN);
  };

  return (
    <div className="relative flex w-full items-center justify-center bg-yellow px-10 pt-4">
      <img src={Logo} alt="logo" />
      <Icon
        name="menu"
        size="none"
        className={`absolute left-3 cursor-pointer ${
          isMenuOpen ? 'hidden' : ''
        }`}
        onClick={() => setIsMenuOpen(true)}
      />

      <div
        className={`fixed left-0 top-0 flex h-screen ${
          isMenuOpen ? 'z-20 w-3/5' : '-z-20 w-0'
        } flex-col items-center justify-between bg-theme pt-6 transition-all duration-300 ease-in-out`}
        ref={menuRef}
      >
        <div className="w-full">
          {MENU_ITEMS.map((item: any) => {
            const { route } = item;
            const isCurrentRoute = location.pathname.startsWith(route);
            const path = route;

            return (
              <SidebarItem
                key={item.label}
                item={item}
                path={path}
                isCurrentRoute={isCurrentRoute}
                isSidebarOpen={isMenuOpen}
                onClick={() => setIsMenuOpen(false)}
              />
            );
          })}
        </div>
        <div className="border-t-1 relative border-grey40">
          <div className="bg-bgGrey	flex items-center justify-between gap-3 px-4 py-5 transition-all">
            <div className="flex items-center gap-2 overflow-hidden">
              <Avatar
                name={currentUser?.name}
                className="size-10 min-w-10 !border-0 text-theme"
              />
              <div className="flex justify-between">
                <div
                  className={`flex w-177 flex-col justify-center truncate  break-words pt-1 `}
                >
                  <div className="overflow-hidden text-ellipsis text-sm font-medium">
                    {currentUser?.name}
                  </div>
                  <div className="overflow-hidden text-ellipsis text-xs font-medium text-grey">
                    {currentUser?.email}
                  </div>
                </div>
                <div
                  className="flex cursor-pointer items-center justify-center"
                  role="presentation"
                  onClick={onClickLogout}
                >
                  <img src={LogoutIcon} alt="logout" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Topbar;
