import { Scalars } from '@types';

export const getRandomId = (): Scalars['ID'] =>
  (Math.random() + 1).toString(36).substring(7);

export const validateWithRegexp = (regex: any, value?: string) =>
  regex.test(value);

export const sanitizeNumberInput = (input: string) =>
  input.replace(/[^0-9]/g, '');

export const getRandomColor = (index?: number) => {
  const colorsArray = [
    '#CBE4F9',
    '#CDF5F6',
    '#EFF9DA',
    '#D6CDEA',
    '#F9D8D6',
    '#EBFFE4'
  ];

  const colorIndex =
    (index || Math.floor(Math.random() * colorsArray.length)) %
    colorsArray.length;

  return colorsArray[colorIndex];
};

export const getFirstLetters = (str: string) =>
  str
    ?.split(' ')
    .map((word) => word[0])
    .filter((_, index) => index < 2)
    .join('')
    .toUpperCase();

export const getAlphabeticIndex = (index: number): string => {
  let alphabeticIndex = '';
  while (index >= 0) {
    alphabeticIndex = String.fromCharCode((index % 26) + 97) + alphabeticIndex;
    index = Math.floor(index / 26) - 1;
  }
  return alphabeticIndex;
};

export const base64ToBlob = (base64: string): Blob | null => {
  try {
    const matches = base64.match(/^data:(.+);base64,(.+)$/);
    if (!matches) {
      throw new Error('Invalid Base64 string');
    }

    const binary = atob(matches[2]);
    const array = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: matches[1] });
  } catch (error) {
    return null;
  }
};

export const displayBase64Image = (base64Data: string): string => {
  const blob = base64ToBlob(base64Data);
  if (!blob) {
    return '';
  }

  const url = URL.createObjectURL(blob);
  return url;
};

export const capitalizeString = (inputString: string): string =>
  inputString.toUpperCase();

export const debouncedSearch = (callback: Function, delay: number) => {
  let timeout: any;
  return (searchTerm: string) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      callback(searchTerm);
    }, delay);
  };
};

export const base64ToPngBlob = (base64: string) => {
  const binary = atob(base64.split(',')[1]);
  const array = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: 'image/png' });
};

export const hasValue = (value: any): boolean => {
  if (Array.isArray(value)) {
    return value.length !== 0;
  }
  if (value && typeof value === 'object' && value.constructor === Object) {
    return Object.keys(value).length !== 0;
  }
  return false;
};
