import homeworkCardBgImageBlue from '@assets/images/card_bg_blue.svg';
import homeworkCardBgImagePink from '@assets/images/card_bg_pink.svg';
import homeworkCardBgImageGreen from '@assets/images/card_bg_green.svg';
import homeworkCardBgImageYellow from '@assets/images/card_bg_yellow.svg';
import homeworkCardBgImageOrange from '@assets/images/card_bg_orange.svg';

import ellipseImageBlue from '@assets/images/ellipse_blue.svg';
import ellipseImagePink from '@assets/images/ellipse_pink.svg';
import ellipseImageGreen from '@assets/images/ellipse_green.svg';
import ellipseImageYellow from '@assets/images/ellipse_yellow.svg';
import ellipseImageOrange from '@assets/images/ellipse_orange.svg';

import catMascotTheme from '../../assets/mascots/cat';
import rabbitMascotTheme from '../../assets/mascots/rabbit';
import tigerMascotTheme from '../../assets/mascots/tiger';
import bearMascotTheme from '../../assets/mascots/bear';
import owlMascotTheme from '../../assets/mascots/owl';

interface MascotTheme {
  homeworkCard: {
    mascotImage: {
      active: any;
      completedHappy: any;
      completedSad: any;
      completedNeutral: any;
      overdue: any;
    };
  };
  resultDialog: {
    correctMascotImage: any;
    partiallyCorrectMascotImage: any;
    incorrectMascotImage: any;
  };
  errorScreen: {
    mascotImage: any;
  };
  summaryDialog: {
    mascotImage: any;
  };
}

export const mascotThemes: MascotTheme[] = [
  owlMascotTheme,
  catMascotTheme,
  rabbitMascotTheme,
  tigerMascotTheme,
  bearMascotTheme
];

interface CardTheme {
  bgImage: any;
  textColor: string;
  progressBar: {
    bgColor: string;
    fgFromColor: string;
    fgToColor: string;
  };
  ellipseImage: any;
}

export const cardThemes: CardTheme[] = [
  {
    bgImage: homeworkCardBgImageBlue,
    textColor: 'text-blue700',
    progressBar: {
      bgColor: 'bg-white/50',
      fgFromColor: '#5EAEF9',
      fgToColor: '#8DD0F8'
    },
    ellipseImage: ellipseImageBlue
  },
  {
    bgImage: homeworkCardBgImagePink,
    textColor: 'text-warning800',
    progressBar: {
      bgColor: 'bg-white/50',
      fgFromColor: '#B52778',
      fgToColor: '#DF66AB'
    },
    ellipseImage: ellipseImagePink
  },
  {
    bgImage: homeworkCardBgImageGreen,
    textColor: 'text-success900',
    progressBar: {
      bgColor: 'bg-white/50',
      fgFromColor: '#06A366',
      fgToColor: '#32C175'
    },
    ellipseImage: ellipseImageGreen
  },
  {
    bgImage: homeworkCardBgImageYellow,
    textColor: 'text-warning800',
    progressBar: {
      bgColor: 'bg-white/50',
      fgFromColor: '#E0A800',
      fgToColor: '#EBCD23'
    },
    ellipseImage: ellipseImageYellow
  },
  {
    bgImage: homeworkCardBgImageOrange,
    textColor: 'text-blue700',
    progressBar: {
      bgColor: 'bg-white/50',
      fgFromColor: '#E58429',
      fgToColor: '#EDAB6D'
    },
    ellipseImage: ellipseImageOrange
  }
];
