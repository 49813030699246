import { ChipProps } from './types';
import Icon from '../icon/Icon';

const Chip = ({
  label,
  className,
  onRemove,
  handleChipClick,
  isCloseIconRequired = true,
  labelStyle = 'max-w-80',
  isTruncate = true
}: ChipProps) => (
  <div
    role="presentation"
    onClick={(e) => handleChipClick?.(label, e)}
    className={`${
      className || ''
    } flex h-7 w-fit items-center rounded-2xl p-4 text-xs font-medium text-content `}
  >
    <div
      className={`${isTruncate ? 'truncate break-words' : 'w-fit'} ${
        labelStyle || ''
      }`}
    >
      {label}
    </div>
    {isCloseIconRequired && onRemove && (
      <div
        role="presentation"
        onClick={onRemove}
        className="ml-2 cursor-pointer"
      >
        <Icon name="close" />
      </div>
    )}
  </div>
);

export default Chip;
