import { ReactElement } from 'react';

import { Navigate } from 'react-router-dom';

import { ERROR } from '@constants/routes';
import { RoleType } from '@types';
import { useGetUserDetailsQuery } from '@features/authentication/queries/authQueries';

interface AccessGuardProps {
  role: RoleType;
  navigateTo?: string;
  children: ReactElement;
}

const AccessGuard = ({ role, navigateTo, children }: AccessGuardProps) => {
  const { data } = useGetUserDetailsQuery(null);

  return role === data?.data?.accessLevels[0]?.name.toUpperCase() ? (
    children
  ) : (
    <Navigate to={navigateTo ?? ERROR.PAGE_404} replace />
  );
};

export default AccessGuard;
